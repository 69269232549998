h6 {
  margin: 0;
}
.line-outer {
  position: relative;
}
.line-inner {
  height: 2px;
  background: #dcdcdc;
  top: 14px;
  left: 8px;
  right: 8px;
  position: absolute;
}
.bubble {
  height: 6px;
  width: 6px;
  background: #dcdcdc;
  border-radius: 50%;
  position: absolute;
}
.bubble-1 {
  top: 12px;
  left: 8px;
}
.bubble-2 {
  top: 12px;
  right: 8px;
}
/*# sourceMappingURL=src/pages/ondemand/flights/results/journey-summary.css.map */