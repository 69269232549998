




















































.result
  cursor pointer
  margin 18px 6px 6px 6px

.flight-journey
  padding-bottom 5px!important

.flight-journey:last-child
  border-top 1px solid #DCDCDC
  padding 16px!important
  padding-bottom 5px!important

.flight-journey:first-child
  border-top 0!important

.price
  border-top 1px solid #DCDCDC
  border-bottom-left-radius 0!important
  text-align right
  @media (min-width: 575px)
    border-top 0
    border-left 1px solid #DCDCDC
    text-align left

