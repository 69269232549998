.subheader {
  font-size: 12px;
  font-weight: 400;
  padding-bottom: 0;
  min-height: 0;
}
.overview-price {
  padding: 8px;
}
.overview-price .total {
  font-size: 1em;
}
.overview-price .price {
  margin: 4px 0;
  font-size: 1.6em;
}
.overview-price .people {
  font-size: 0.8em;
}
/*# sourceMappingURL=src/pages/ondemand/flights/sidebar/index.css.map */