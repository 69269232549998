






















































































































































































.layout-padding
  max-width 768px
hr
  width 100%
  margin-bottom 16px
button
  width 100%

span, .hr
  margin-top 16px

.summary-footer
  height 60px
  align-items center

.scroll
  overflow-y scroll

.fare-rules-text
  max-width 100%
  word-wrap break-word
  overflow-wrap break-word
  font-family Roboto
