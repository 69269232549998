@media (max-width: 1199px) {
  .layout-padding {
    padding: 1em;
  }
}
.extras {
  max-width: 768px;
  margin: auto;
}
.q-card-primary + .q-card-section {
  padding-top: 16px;
}
/*# sourceMappingURL=src/pages/ondemand/flights/extras/index.css.map */