

































































































































.layout-padding
  @media (max-width: 1199px)
    padding 1em
.extras
  max-width 768px
  margin auto
.q-card-primary + .q-card-section
  padding-top 16px
