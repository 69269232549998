.result {
  cursor: pointer;
  margin: 18px 6px 6px 6px;
}
.flight-journey {
  padding-bottom: 5px !important;
}
.flight-journey:last-child {
  border-top: 1px solid #dcdcdc;
  padding: 16px !important;
  padding-bottom: 5px !important;
}
.flight-journey:first-child {
  border-top: 0 !important;
}
.price {
  border-top: 1px solid #dcdcdc;
  border-bottom-left-radius: 0 !important;
  text-align: right;
}
@media (min-width: 575px) {
  .price {
    border-top: 0;
    border-left: 1px solid #dcdcdc;
    text-align: left;
  }
}
/*# sourceMappingURL=src/pages/ondemand/flights/results/result.css.map */