.flight-details {
  margin: 16px 0;
}
.line {
  position: relative;
  margin: 8px;
  height: 2px;
  background-color: currentColor;
}
.line:before,
.line:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: currentColor;
  border-radius: 100%;
  top: -4px;
}
.line:before {
  left: 0;
}
.line:after {
  right: 0;
}
/*# sourceMappingURL=src/pages/ondemand/flights/summary/flight-detail.css.map */