.flights-container {
  height: calc(100vh - 64px) !important;
}
>>> .wrapper {
  margin: auto;
  max-width: 720px;
}
.bundle,
.title {
  margin: auto;
  margin-bottom: 16px;
  margin-top: 16px;
}
.results {
  margin: auto;
}
.info-bar {
  position: relative;
  z-index: 25;
  padding: 24px 8px 16px;
  border-bottom: 1px solid;
  margin: auto;
  border-color: #e0e0e0;
}
.title {
  margin: 0;
}
.sort {
  border: 1px solid;
  border-color: #e0e0e0;
}
/*# sourceMappingURL=src/pages/ondemand/flights/results/index.css.map */