



































































































































































































































































































































































































































































.layout-padding
  max-width 768px
.extras
  max-width 768px
  margin auto
.q-card-primary + .q-card-section
  padding-top 16px
.q-card-dark > *
  font-size 14px!important
